import React from 'react'
import { isEqual } from 'lodash'

function normalizeValue (value) {
  if (value === false) return false

  return value || ''
}

export default function withFormHandler (Component, config = {}) {
  return class FormHandler extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        submitting: false,
        values: this.initialValues
      }

      this.change = this.change.bind(this)
      this.changeAll = this.changeAll.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.reset = this.reset.bind(this)
    }

    componentDidUpdate (prevProps) {
      const { initialValues } = this.props
      const { initialValues: prevInitialValues } = prevProps
      const { values } = this.state

      if (!config.enableReinitialize) return
      if (isEqual(initialValues, prevInitialValues)) return

      const newValues = Object.keys({
        ...prevInitialValues,
        ...initialValues
      }).reduce((memo, key) => {
        if (prevInitialValues[key] !== initialValues[key]) {
          memo[key] = initialValues[key]
        }

        return memo
      }, {})

      this.setState({
        values: {
          ...values,
          ...newValues
        }
      })
    }

    get initialValues () {
      return this.props.initialValues || config.initialValues || {}
    }

    change (fieldName, value) {
      const { values } = this.state

      this.setState({
        values: {
          ...values,
          [fieldName]: value
        }
      })
    }

    changeAll (fieldValues) {
      const { values } = this.state

      this.setState({
        values: {
          ...values,
          ...fieldValues
        }
      })
    }

    handleChange (ev) {
      const { target } = ev
      let { value, type } = target

      if (type === 'checkbox') {
        value = target.checked
      } else if (type === 'select-multiple') {
        value = [...target.options].reduce((memo, option) => {
          if (option.selected) {
            memo.push(option.value)
          }
          return memo
        }, [])
      } else if (type === 'radio' && !target.checked) {
        // the checked radio will set the new value
        return
      } else if (type === 'file') {
        value = ev.target.files
      }

      this.change(target.name, value)
    }

    handleSubmit (callback) {
      return ev => {
        ev && ev.preventDefault()
        this.setState({
          submitting: true,
          error: null
        })

        const { values } = this.state

        try {
          const result = callback(values)

          if (result && result.constructor.name === 'Promise') {
            return result
              .then(() => {
                this.setState({ submitting: false })
              })
              .catch(error => {
                this.setState({
                  error,
                  submitting: false
                })
              })
          }

          this.setState({ submitting: false })

          return result
        } catch (error) {
          this.setState({
            submitting: false,
            error
          })

          throw error
        }
      }
    }

    reset () {
      const { values } = this.state
      const newValues = Object.keys(values).reduce((memo, name) => {
        memo[name] = normalizeValue(this.initialValues[name])
        return memo
      }, {})
      this.setState({
        values: newValues,
        error: null
      })
    }

    render () {
      const { values, submitting, error } = this.state

      return (
        <Component
          {...this.props}
          values={values}
          error={error}
          submitting={submitting}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          reset={this.reset}
          change={this.change}
          changeAll={this.changeAll}
        />
      )
    }
  }
}
