import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { keys } from 'lodash'
import Head from 'next/head'
import Link from 'next/link'
import { withRouter } from 'next/router'
import Layout, { siteTitle } from '@/components/layout'
import NavBar from '@/components/nav-bar'
import FormHandler from '@/components/form-handler'
import ErrorMessage from '@/components/input-error-message'
import { loginUser } from '@/lib/redux/actions'

const DEFAULT_LOGIN_PATH = '/disclaimer'

function Login (props) {
  const { loginUser, handleSubmit, submitting, handleChange, values, router } =
    props

  const { query, isReady } = router
  const [errors, setErrors] = useState(null)
  const [redirectPath, setRedirectPath] = useState(DEFAULT_LOGIN_PATH)

  useEffect(() => {
    if (!isReady) return

    if (query.redirect) {
      setRedirectPath(query.redirect)
    }
  }, [query])

  const validateForm = async () => {
    const errors = {}

    if (!values.email) {
      errors.email = 'required'
    }

    if (!values.password) {
      errors.password = 'invalid' // njsscan-ignore: node_password
    }

    if (keys(errors).length > 0) {
      setErrors(errors)
      return false
    }

    setErrors(null)
    return true
  }

  const onSubmit = values => {
    if (!validateForm()) return

    return loginUser(values)
      .then(() => {
        window.location = redirectPath
      })
      .catch(error => {
        setErrors({ email: error.response.data.error })
      })
  }

  return (
    <Layout>
      <Head>
        <title>Login - {siteTitle}</title>
      </Head>

      <NavBar />

      <div className='container py-5'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-8 col-lg-6'>
            <h1 className='card-title mb-4 text-center'>
              Healthcare Provider Portal
            </h1>

            <div className='card bg-transparent mx-auto w-75 border-0'>
              <div className='card-body'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='fs-small'>
                    <label className='form-label' htmlFor='email'>
                      Email
                    </label>
                    <ErrorMessage error={errors?.email} />
                    <input
                      name='email'
                      id='email'
                      value={values.email || ''}
                      onChange={handleChange}
                      type='text'
                      className='form-control py-2 border border-dark rounded'
                      required
                    />
                  </div>

                  <div className='fs-small'>
                    <label className='form-label' htmlFor='password'>
                      Password
                    </label>
                    <ErrorMessage error={errors?.password} />
                    <input
                      name='password'
                      id='password'
                      value={values.password || ''}
                      onChange={handleChange}
                      type='password'
                      className='form-control py-2 border border-dark rounded'
                      required
                    />
                  </div>

                  <button
                    type='submit'
                    className='btn text-i-white bg-i-green px-5 py-3 mt-4 w-100 rounded-pill d-block'
                    disabled={submitting}
                  >
                    {submitting ? 'Logging you in...' : 'Sign in'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function mapStateToProps (state) {
  const { currentUser } = state

  return {
    currentUser
  }
}

const mapDispatchToProps = {
  loginUser
}
const routed = withRouter(Login)
const form = FormHandler(routed)
const connected = connect(mapStateToProps, mapDispatchToProps)(form)

export default connected
