import Image from 'next/image'
import Link from 'next/link'
import logo from '@/public/images/logo.svg'

const NavBar = () => {
  return (
    <div className='container-fluid bg-white'>
      <nav className='navbar navbar-expand-lg navbar-light py-4 mb-4 mb-lg-5'>
        <div className='container'>
          <Link className='navbar-brand mx-auto' href='https://www.invoy.com'>
            <div>
              <Image src={logo} alt='Invoy logo' width='120' height='32' />
            </div>
          </Link>
        </div>
      </nav>
    </div>
  )
}

export default NavBar
