export default function ErrorMessage (props) {
  const { error, message, className } = props

  if (!error) return ''

  return (
    <label className={` ${className} text-danger fs-small float-end`}>
      * {message || error}
    </label>
  )
}
